<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div class="modal">
      <div class="modal-content">
        <span class="close" @click="$emit('close')">&times;</span>
        <img class="logo" :src="logoSrc" />
        <video
          v-if="isMovie"
          controls="controls"
          autoplay
          loop
          width="400"
          height="600"
          :name="image"
          :src="image"
        ></video>
        <img class="image" v-else :src="imageSrc" />
        <p>{{ caption }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    facet: Object
  },

  computed: {
    caption() {
      return this.facet.caption;
    },

    image() {
      let imageName = "image.jpg";
      if (this.facet.image) {
        imageName = this.facet.image;
      }
      return imageName;
    },

    logo() {
      return this.facet.logo;
    },

    imageSrc() {
      return require("@/assets/" + this.logo + "/" + this.image);
    },

    isMovie() {
      return this.image.match(/\.mov/);
    },

    logoSrc() {
      return require("@/assets/" + this.logo + "/logo.png");
    }
  },

  data() {
    return {
      showModal: false
    };
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.modal-overlay {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1; /* Sit on top */
}

.modal {
  text-align: left;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 600px;
  max-width: 100%;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 5% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  box-sizing: border-box;

  .logo {
    max-width: 80px;
  }
  img.image {
    max-width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
