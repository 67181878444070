<template>
  <div>
    <Modal
      v-if="currentFacet"
      :facet="currentFacet"
      @close="currentFacet = null"
    />

    <img src="@/assets/ali-nav.png" class="aliNav notSilhoutte" />
    <img src="@/assets/ali-silhoutte.png" class="aliNav silhoutte" />
    <audio
      class="player"
      ref="player"
      src="respect.m4a"
      loop
      controls="controls"
    ></audio>
    <nav class="circular-menu">
      <div :class="{ circle: true, open: menuOpen }">
        <AliFacet
          v-for="(facet, index) in facets"
          :key="facet.logo"
          :index="index"
          :facet="facet"
          :numberOfFacets="facets.length"
          @select="currentFacet = facet"
        />
      </div>
    </nav>
  </div>
</template>

<script>
import AliFacet from "@/components/AliFacet";
import Modal from "@/components/Modal";
import Facets from "@/facets.json";

export default {
  components: { AliFacet, Modal },
  props: {
    msg: String
  },

  data() {
    return {
      currentFacet: null,
      facets: Facets,
      menuOpen: false
    };
  },

  computed: {
    randomFacets() {
      const n = 5;
      return this.facets
        .map((x) => ({ x, r: Math.random() }))
        .sort((a, b) => a.r - b.r)
        .map((a) => a.x)
        .slice(0, n);
    }
  },

  mounted() {
    setTimeout(() => (this.menuOpen = true), 3000);
    this.$refs.player.play();
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aliNav {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 90%;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notSilhoutte {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 0;
}

.silhoutte {
  animation-name: fadeOut;
  animation-duration: 3s;
  animation-fill-mode: both;
  opacity: 1;
}

@keyframes circle {
  from {
    -webkit-transform: rotateZ(0deg);
  }
  to {
    -webkit-transform: rotateZ(360deg);
  }
}
.circular-menu {
  width: 100%;
  height: 750px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0%;
  // animation: circle 10s infinite linear;
}

.circle {
  width: 100%;
  height: 750px;
  opacity: 0;
  position: relative;

  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);

  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.open.circle {
  opacity: 1;

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.player {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
