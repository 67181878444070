<template>
  <div>
    <h1>What's the password?</h1>

    <input
      autofocus
      v-model="password"
      type="text"
      @keyup.enter="checkPassword"
    />
    <div v-if="passwordInvalid">I'm sorry, that's not the right password</div>
    <p>
      <button @click.prevent="checkPassword">Enter</button>
    </p>

    <p>(Best in full screen with sound on)</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      passwordInvalid: false
    };
  },

  methods: {
    checkPassword() {
      if (this.password === "chickens") {
        this.$emit("password-valid");
      } else {
        this.passwordInvalid = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
