<template>
  <div id="app">
    <PasswordPage v-if="!loggedIn" @password-valid="passwordValid" />
    <AliNav v-else-if="showMainPage" />
    <TitlePage @next-page="showMainPage = true" v-else />
  </div>
</template>

<script>
import AliNav from "./components/AliNav.vue";
import PasswordPage from "./components/PasswordPage.vue";
import TitlePage from "./components/TitlePage.vue";

export default {
  name: "App",
  components: {
    AliNav,
    PasswordPage,
    TitlePage
  },

  data() {
    return {
      loggedIn: false,
      showMainPage: false
    };
  },

  methods: {
    passwordValid() {
      localStorage.loggedIn = true;
      this.loggedIn = true;
    }
  },

  mounted() {
    this.loggedIn = localStorage.loggedIn;
  }
};
</script>

<style>
body {
  background-image: url("assets/background.png");
  background-repeat: repeat-y;
  background-color: #f3bcd6;
}

.stripe-1 {
  background-color: #fec464;
}

.stripe-2 {
  background-color: #ffe7d2;
}

.stripe-3 {
  background-color: #f3bcd6;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
