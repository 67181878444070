<template>
  <div>
    <h1>Just who is</h1>
    <img src="@/assets/ali-harnell.png" />
    <p>
      <a href="#" @click.prevent="$emit('next-page')"
        >Click here to find out.</a
      >
    </p>
  </div>
</template>

<script>
export default {
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
