<template>
  <div>
    <a
      :style="{ left: getLeft, top: getTop }"
      href="#"
      @click.prevent="$emit('select')"
    >
      <img :src="logoSrc" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    facet: Object,
    index: Number,
    numberOfFacets: Number
  },

  computed: {
    logo() {
      return this.facet.logo;
    },

    logoSrc() {
      return require("@/assets/" + this.logo + "/logo.png");
    },

    getLeft() {
      return (
        (
          50 -
          35 *
            Math.cos(
              -0.5 * Math.PI -
                2 * (1 / this.numberOfFacets) * this.index * Math.PI
            )
        ).toFixed(4) + "%"
      );
    },

    getTop() {
      return (
        (
          50 +
          35 *
            Math.sin(
              -0.5 * Math.PI -
                2 * (1 / this.numberOfFacets) * this.index * Math.PI
            )
        ).toFixed(4) + "%"
      );
    }
  },

  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes ccircle {
  from {
    -webkit-transform: rotateZ(360deg);
  }
  to {
    -webkit-transform: rotateZ(0deg);
  }
}
a {
  // animation: ccircle 10s infinite linear;
  text-decoration: none;
  display: block;
  height: 40px;
  width: 220px;
  line-height: 40px;
  margin-left: -100px;
  margin-top: -100px;
  position: absolute;
  text-align: center;

  img {
    // filter: grayscale(100%);
    width: 70%;
    z-index: 0;
  }
  img:hover {
    filter: none;
    width: 100%;
    z-index: 1;
  }
}
</style>
